import React from "react";
import "./RubberProduct.css";
//import Image from "../../images/RubberProduct.jpg"
import {
  Parallax,
  ParallaxProvider,
  ParallaxBanner,
  ParallaxBannerLayer,
} from "react-scroll-parallax";

const RubberProductMaincontent = () => {
  return (
    <ParallaxProvider>
      <div className="responsive-rubberProduct">
        <div className="rubberProduct-pc">
          <ParallaxBanner style={{ aspectRatio: "2 / 1", width: "100%" }}>
            <ParallaxBannerLayer
              image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/rubber-products/tile-01-a.jpg"
              speed={7}
            />
          </ParallaxBanner>
        </div>
        <div className="rubberProduct-mobile">
          <ParallaxBanner style={{ aspectRatio: "auto 360 / 540" }}>
            <ParallaxBannerLayer
              image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/rubber-products/tile-01-a.webp"
              speed={10}
            />
          </ParallaxBanner>
        </div>

        <div className="first-para" style={{ marginBottom: "200px" }}>
          <p className="para">
            At Lalan, quality is a way of life. That's why each step of our
            operational processes undergoes rigid quality management practices
            to produce the highest quality latex in Sri Lanka.
            <br />
            <br />
            As one of the largest rubber manufacturing companies in the Asian
            region, Lalan offers a comprehensive range of rubber and value added
            rubber products and it is our privilege to be the preferred partner
            for the world's largest brands.
            <br />
            <br />
            Our products range from premium quality organic natural latex
            mattresses and pillows, high tensile strength, biodegradable rubber
            bands and tubes to premium industrial gloves and cutting edge
            seamless dipped gloves. Lalan's seamless network of latex suppliers
            ensure efficiency and consistency of our centrifuge operations and
            our superior quality crepe rubber and RSS, are produced to exacting
            international standards with minimum environmental impact.
            <br />
            <br />
            We believe in sustainable development and our outgrower programmes,
            that support rubber smallholders, are an integral part of the
            development of the rubber industry in Sri Lanka.
            <br />
            <br />
          </p>
        </div>

        <div className="RPpara">
          {/* ......... 1 .................................................... */}

          <div>
            <div className="two-column">
              <div className="column-2">
                {/* Column 1 */}
                <Parallax speed={-7}>
                  <ParallaxBanner style={{ aspectRatio: "2 / 2" }}>
                    <ParallaxBannerLayer
                      image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/rubber-products/gal-01.webp"
                      speed={10}
                      style={{ backgroundSize: "cover" }}
                    />
                  </ParallaxBanner>
                  <br />
                  <h1 className="topic">Rubber Bands / Tubes</h1>
                  <p className="para">
                    High quality rubber bands and tubes are a part of Lalan's
                    impressive rubber products and rubber manufacturing
                    portfolio. Using the finest quality latex crepe rubber from
                    our plantations, Lalan manufactures over 600 metric tons of
                    rubber bands annually, for export to countries such as
                    Germany, Japan, Jordan and India. <br />
                    <br />
                    Our high quality range of biodegradable rubber bands are
                    produced in a variety of colours and sizes for the
                    horticulture, agriculture, food and catering industries, and
                    a wide array of industrial and consumer applications across
                    the world. With the highest tensile strength, durability and
                    flexibility, our sturdy rubber bands are food grade
                    certified and conform to stringent international standards.{" "}
                    <br />
                    <br />
                    Lalan also manufactures a specialty range of latex rubber
                    tubes predominantly used in denim garment production. These
                    seamless high quality tubes are manufactured in a variety of
                    sizes and offer advanced durability, strength and
                    flexibility with enhanced temperature resistance and
                    stability. <br />
                    <br />
                  </p>

                  <p className="address">
                    Lalan Rubbers (Private) Limited <br />
                    95B, Zone A, Export Processing Zone, <br />
                    Biyagama, Malwana, <br />
                    Sri Lanka. <br />
                    info@lalangroup.com <br />
                    (+94) 431 1200 <br />
                    (+94) 431 1222 <br />
                    <br />
                  </p>
                </Parallax>
              </div>

              <div className="column-2">
                {/* Column 2 */}

                <Parallax speed={20}>
                  <ParallaxBanner style={{ aspectRatio: "2 / 2" }}>
                    <ParallaxBannerLayer
                      image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/rubber-products/tile-03.webp"
                      speed={10}
                      style={{ backgroundSize: "cover" }}
                    />
                  </ParallaxBanner>
                  <br />
                  <h1 className="topic">Mattresses and Pillows</h1>
                  <p className="para">
                    Under our sustainable living policy, Lalan produces a range
                    of the highest quality latex mattresses and pillows under
                    stringent conformity to international standards and
                    certifications. Using the finest organic natural latex,
                    these mattresses and pillows are ergonomically designed for
                    optimum comfort, buoyancy and support and incorporate
                    natural hypoallergenic, antimicrobial, insect repellent, and
                    fire and water resistant properties. By using only the
                    highest quality of organic natural latex with no harsh
                    chemicals, we ensure that each mattress and pillow that we
                    manufacture is of the highest standards. <br />
                    <br />
                    Lalan's comprehensive botanical latex mattress and pillow
                    range caters to industrial use, in specialised industries
                    such as hospitality and healthcare, as well as consumer and
                    retail purposes. <br />
                    <br />
                    Our superior export packaging methodology ensures the
                    maximum protection of our products. With vacuum seal
                    protected packaging to withstand the pressures of shipping
                    and transportation, we ensure that our product quality stays
                    consistent from our factory to your door. <br />
                    <br />
                  </p>

                  <p className="address">
                    Lalan Eco-Latex (Private) Limited. <br />
                    Lot 72, Phase III, KEPZ Katunayake, <br />
                    Sri Lanka. <br />
                    info@eco-latex.com <br />
                    (+94) 11 225 1960 <br />
                    <br />
                  </p>
                </Parallax>
              </div>
            </div>
          </div>

          {/* ......... 2 .................................................... */}

          <div>
            <div className="two-column">
              <div className="column-2">
                {/* Column 1 */}
                <Parallax speed={7}>
                  <ParallaxBanner style={{ aspectRatio: "2 / 2" }}>
                    <ParallaxBannerLayer
                      image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/rubber-products/tile-04.webp"
                      speed={10}
                      style={{ backgroundSize: "cover" }}
                    />
                  </ParallaxBanner>
                  <br />
                  <h1 className="topic">
                    FSC&trade; Certified Centrifuged Latex / Organic Latex
                  </h1>
                  <p className="para">
                    Lalan's centrifuging plants, with a capacity of 100,000
                    liters of field latex a day, are situated in the central
                    rubber heartlands of Sri Lanka and conform to the highest
                    international standards represented by the ISO 9001:2015
                    Quality Management System and adhere to strict waste
                    treatment practices. <br />
                    <br />
                    Our strength stems from our network of over 1,000 latex
                    suppliers, delivering rubber sourced from single origin
                    estates, to ensure consistency and the highest standards of
                    FSC&trade; certified quality, and this seamless network of
                    collection and distribution enables us to maintain
                    uninterrupted supply, quality, and efficiency. <br />
                    <br />
                    Lalan also takes the lead in organic rubber cultivation,
                    with dedicated organic rubber plantations producing premium
                    quality organic latex conforming to the highest
                    international standards. <br />
                    <br />
                    Our outgrower programme which distributes over 300,000
                    plants to rubber smallholders each year is in line with our
                    core policies to maintain ethical, sustainable practices in
                    our operations to strengthen rubber smallholders and the
                    rubber industry in Sri Lanka. <br />
                    <br />
                  </p>

                  <div className="pc-s">
                    <br />
                    <img
                      className="fsc-image-2"
                      src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/rubber-products/fsc_logo_2-2.webp"
                      style={{ marginTop: "50px" }}
                    ></img>
                    <br />
                    <div className="para-plantaion-link">
                      FSC&trade;-certified products are available upon request
                      as the website is common for the group:
                      <a
                        href="https://www.lalangroup.com/"
                        className="para-plantaion"
                      >
                        {" "}
                        www.lalangroup.com
                      </a>
                    </div>
                    <br />
                    <br />
                  </div>

                  <p className="address">
                    Lalan Rubbers (Private) Limited. <br />
                    54, Kirulapone Avenue, <br />
                    Colombo 06, <br />
                    Sri Lanka. <br />
                    info@lalangroup.com <br />
                    (+94) 11 574 4555 <br />
                    <br />
                  </p>
                </Parallax>
              </div>

              <div className="column-2">
                {/* Column 2 */}

                <Parallax speed={20}>
                  <ParallaxBanner style={{ aspectRatio: "2 / 2" }}>
                    <ParallaxBannerLayer
                      image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/rubber-products/tile-05.webp"
                      speed={10}
                      style={{ backgroundSize: "cover" }}
                    />
                  </ParallaxBanner>
                  <br />
                  <h1 className="topic">Crepe Rubber / RSS</h1>
                  <p className="para">
                    As one of Sri Lanka's leading rubber manufacturing companies
                    and latex suppliers, Lalan's expertise in crepe rubber
                    manufacture spans over 50 years and we are the undisputed
                    leader in producing the highest quality crepe rubber in the
                    nation. <br />
                    <br />
                    With increasing demand for Lalan's superior quality, ISO
                    certified latex crepe at the Colombo Rubber Auctions, our
                    factories and processes have been streamlined to incorporate
                    the latest technology and manufacturing methods to produce
                    in excess of 75,000 kg of latex crepe and over 80,000 kg of
                    RSS rubber per month. <br />
                    <br />
                    Our production facilities have been designed to generate
                    maximum output in production with minimum environmental
                    impact. Our cutting edge effluent treatment plant is the
                    most technologically advanced plant in Sri Lanka and exceeds
                    international standards and specifications. <br />
                    <br />
                    To further our commitment to green initiatives and eco
                    preservation, Lalan also exports large quantities of scrap
                    rubber for use in a multitude of industrial applications.{" "}
                    <br />
                    <br />
                  </p>

                  <div className="mobile-s">
                    <br />
                    <img
                      className="fsc-image-2"
                      src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/rubber-products/fsc_logo_2-2.webp"
                      style={{ marginTop: "50px" }}
                    ></img>
                    <br />
                    <div className="para-plantaion-link">
                      FSC&trade;-certified products are available upon request
                      as the website is common for the group:
                      <a
                        href="https://www.lalangroup.com/"
                        className="para-plantaion"
                      >
                        {" "}
                        www.lalangroup.com
                      </a>
                    </div>
                    <br />
                    <br />
                  </div>

                  <p className="address">
                    Lalan Rubbers (Private) Limited <br />
                    54, Kirulapone Avenue, <br />
                    Colombo 06, <br />
                    Sri Lanka. <br />
                    info@lalangroup.com <br />
                    (+94) 11 574 4555 <br />
                    <br />
                  </p>
                </Parallax>
              </div>
            </div>
          </div>
        </div>

        <div className="rubberProduct-pc">
          <center>
            <h1 className="bigHugeTitle">
              9 Sectors <br />
              of Integrated <br />
              <span
                style={{
                  color: "#46708a",
                  fontStyle: "italic",
                  fontWeight: "bold",
                }}
              >
                Expertise
              </span>
            </h1>
            <div style={{ marginTop: "100px", marginBottom: "200px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "200px",
                }}
              >
                <div style={{ width: "2px", background: "#46708a" }}></div>
              </div>
            </div>
          </center>
        </div>

        <div className="rubberProduct-mobile">
          <p className="text-max-rubberProduct">
            9 Sectors of Integrated
            <span
              style={{
                color: "#46708a",
                fontStyle: "italic",
                fontWeight: "bold",
              }}
            >
              Expertise
            </span>
          </p>
        </div>

        <div></div>
      </div>
    </ParallaxProvider>
  );
};

export default RubberProductMaincontent;
